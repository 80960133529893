import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/bin.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/book.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/carpet.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/code.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/dekstop.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/dot.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/flower-top-big.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/flower-top.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/headphone.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/keyboard.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/main-pic.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/man.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/pen.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/table.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/banner-image/tea-cup.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/shape1.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/shape2.svg");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/shape3.svg");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/shape4.svg");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/public/images/shape5.png");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/src/components/Contact/ContactForm.tsx");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/src/components/ITStartup/Features.tsx");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/src/components/ITStartup/OurClients.tsx");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/src/components/ITStartup/OurProcess.tsx");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/src/components/ITStartup/ServicesArea/index.tsx");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/src/components/ITStartup/TechStack.tsx");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/src/components/ITStartup/WhyChooseUs.tsx");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/src/components/Layout/Footer.tsx");
import(/* webpackMode: "eager" */ "/Users/rakesh/Development/Projects/zensolve/src/components/Layout/Navbar.tsx")